<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row justify="end">
      <v-col style="text-align: right">
        <v-btn
          color="secondary_dark"
          dark
          depressed
          tile
          to="/baza-wiedzy-nowa"
        >
          Dodaj nową kategorie
        </v-btn>
      </v-col>
    </v-row>
    

    <v-row>

      <v-col
        v-for="item in list"
        :key="item.id"
        cols="3"
      >
        <v-card
          color="#385F73"
          dark
          @click="userClick(item.id)"
        >
          <v-card-title class="headline">
            {{item.name}}
          </v-card-title>

          <v-card-subtitle>{{item.description}}</v-card-subtitle>

        </v-card>
      </v-col>
    </v-row>


  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Baza wiedzy',
        disabled: true,
        to: '/baza-wiedzy-lista',
      },
    ],

    list: [
      {
        id: 1,
        name: 'Nazwa Klienta',
        email: 'email@email.com',
        number: '12313131233'
      },
    ],
  }),
  methods: {
    userClick(value) {
      this.$router.push('/baza-wiedzy/kategoria/'+value);
    }
  },
  mounted(){
    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/faq_category', data: {}, method: 'GET' })
      .then(resp => {
        this.list = resp.data;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
}
</style>